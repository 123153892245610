/* Rating */

.br-theme-fontawesome-stars,
.br-theme-css-stars {
  .br-widget {
    a {
      font-size: 20px;
      &.br-selected,
      &.br-active {
        &:after {
          color: $primary;
        }
      }
    }
  }
}

.br-theme-bars-1to10,
.br-theme-bars-movie,
.br-theme-bars-pill,
.br-theme-bars-reversed,
.br-theme-bars-horizontal {
  .br-widget {
    a {
      background-color: rgba($primary, .6);
      color: $primary;
      &.br-selected,
      &.br-active {
        background-color: $primary;
      }
    }
  }
}

.br-theme-bars-square {
  .br-widget {
    a {
      background: lighten($dark, 30%);
      border-color: rgba($primary, .5);
      color: rgba($primary, .5);
      &.br-selected,
      &.br-active {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}

.br-theme-bars-movie,
.br-theme-bars-reversed,
.br-theme-bars-1to10,
.br-theme-bars-horizontal {
  .br-widget {
    .br-current-rating {
      color: $body-color;
      font-size: $default-font-size;
      font-weight: initial;
    }
  }
}

.br-theme-bars-1to10 {
  height: 26px;
  .br-widget {
    height: 24px;
    .br-current-rating {
      line-height: 1;
    }
  }
}

.br-theme-bars-pill {
  .br-widget {
    a {
     line-height: 2.5;
    }
  }
}